export const menuHU = [
  {
    name: 'Főoldal',
    url: '/'
  },
  {
    name: 'Arc',
    url: '/arc'
  },
  {
    name: 'Medence',
    url: '/medence'
  },
  {
    name: 'Torony I.',
    url: '/toronyegy'
  },
  {
    name: 'Torony II.',
    url: '/toronyketto'
  },
  {
    name: 'Rom',
    url: '/rom'
  }
];

export const menuEN = [
  {
    name: 'Home',
    url: '/en'
  },
  {
    name: 'Face',
    url: '/face'
  },
  {
    name: 'Pool',
    url: '/pool'
  },
  {
    name: 'Tower I.',
    url: '/towerone'
  },
  {
    name: 'Tower II.',
    url: '/towertwo'
  },
  {
    name: 'Ruin',
    url: '/ruin'
  }
];
