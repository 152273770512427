import React from 'react';
import { Link } from 'react-router-dom';
import hero_desktop from '../assets/images/pa_hero_en.jpg';
import hero_mobile from '../assets/images/pa_hero_mobile_en.jpg';
import intro_en from '../assets/audio/INTRO_en.mp3';

import '../styles/home.css';
import AudioPlayer from '../components/AudioPlayer';
import Menu from '../components/Menu';

const HomeEN = () => {
  const hero = window.innerWidth > 600 ? hero_desktop : hero_mobile;

  return (
    <div id="home">
      <header>
        <Link className="lang" to="/">HU</Link>
        <Menu lang="EN"/>

        <div className="img-wrapper">
          <img src={hero} alt="Face, Pool, Two Towers and Ruin"/>
        </div>
        <div className="info-wrapper">
          <div className="map">
            <p><a href="https://www.google.com/maps/d/viewer?mid=1YT3mecH7cjAVRyg37EjWYVhPzMRJj27w&hl=en&ll=47.106405161825535%2C17.918316056027606&z=15" target="_blank"
                  rel="noopener noreferrer">Map</a></p>
          </div>
          <AudioPlayer url={intro_en} title="User guide: Welcome to Haszkovo"/>
        </div>
      </header>
      <main>
        <section>
          <h1>Face, Pool, Two Towers and Ruin</h1>
          <h2>Creative Urban Interventions to Haszkovó Housing Estate</h2>
          <p>Modernist housing estates are dismissed, as is Haszkovó in the city of Veszprém. Their stories are understood as the stories of failed urban developments, they are said to be grey, sad
            and soulless. Can something be soulless and disenchanted that gives home to 20 000 inhabitants? Let’s have a thought exercise and put our biases aside. Are we ready to reconsider our
            relation to the housing projects and think of them as “real cities” for a moment? If so, how can we go even further and fill it up with creative ideas?</p>
          <p>In the framework of Veszprém Design Week, the exhibition invites visitors to experience change in the current condition of Haszkovó brought about by five portable and durable urban
            artifacts in the open air.</p>
          <p>The five objects serves as playful, outdoor furniture and their architectural quality also helps us to perceive Haszkovó as something similar to a lovely historical downtown in any
            cities. In the centre of Veszprém these are common objects, such as the places “where we meet before going to party” or “where we had our first kiss”. The ‘Fire Lookout Tower’, the ‘Statue
            of Zsuzsi’ or the ‘Clock’ deliver this sensation of orientation and memory for the collectiv, inextricably embedded in the urban texture of Veszprém. The exhibition provides an alternative
            to establish distinctive objects also in Haszkovó.</p>
          <p>Last year the show 12 Walls was the very first step of a large scale international collaborative project. That time emerging architects observed and presented the problem of
            ornamentation. This year five other young studios and architects from Greece, United Kingdom, Italy, Spain and Hungary address the problem of urban artifacts.</p>
        </section>
        <section>
          <h2>Participants</h2>
          <p><a href="https://www.edwardcrooks.co.uk/tagged/news" target="_blank" rel="noopener noreferrer">Edward Crooks</a></p>
          <p><a href="http://www.pointsupreme.com/content/" target="_blank" rel="noopener noreferrer">Point Supreme</a></p>
          <p><a href="https://www.supervoid.eu/" target="_blank" rel="noopener noreferrer">Supervoid</a></p>
          <p><a href="https://www.maio-architects.com/" target="_blank" rel="noopener noreferrer">MAIO</a></p>
          <p><a href="https://paradigmaariadne.com/" target="_blank" rel="noopener noreferrer">Paradigma Ariadné</a></p>
        </section>
        <section>
          <h2>Impressum</h2>
          <p>Organizers: Paradigma Ariadné</p>
          <p>Curators: Attila Róbert Csóka, Szabolcs Molnár, Dávid Smiló</p>
          <p>Graphic, web design: Adrienn Császár</p>
          <p>Code: Júlia Erő</p>
          <p>Audio guide copywriting, editing: Szilvi Német</p>
          <p>Audio guide, translation: Adele Eisenstein</p>
          <p>Sound designer: Márton Bertók</p>
          <p>English voices (in order of appearance):<br/>Ian Cook, Gorana Misic, An American Girl, Szilvi Német, Esther Holbrook, TTS</p>
          <br/>
          <p>The exhibition is sponsored by Veszprém-Balaton 2023 Zrt. and organised by Paradigma Ariadné.</p>
        </section>
        <section>
          <h2>Bibliography of the Audio Guide</h2>
          <p>Beshty, Waled & Schwab, Eric: Wilkommen in Irak! Cabinet Magazine. Issue 15 / The Average</p>
          <p>Friedman, Alice T.: American Glamour 2.0: architecture, spectacle and social media. Consumption, Markets & Culture, 2017</p>
          <p>Jencks, Charles: The language of Post-Modern Architecture</p>
          <p>Lynch, Kevin: The Image of the City. MIT Press, 1960</p>
          <p>Lange, Alexandra: Is Instagram Ruining Architecture? The New York Times, 2019</p>
          <p>Moore, Rowan : Pruitt-Igoe: death of the American urban dream. The Observer, Architecture. 2012</p>
          <p>Rossi, Aldo: The Architecture of the City. Oppositions Books, 1984</p>
          <p>Rubin, Eli: Amnesiopolis: modernity, space and memory in East Germany. Oxford University Press, 2016</p>
          <p>Új építészet, új társadalom 1945-1978. Válogatás az elmúlt évtizedek építészeti vitáiból, dokumentumaiból. Művészet és elmélet. Corvina kiadó, 1981</p>
          <p>Útkeresés a ’70-es évek lakásépítésében I. Lechner Tudásközpont, 2017</p>
          <p>Városi folklór. A veszprémi Jutasi úti lakótelep az emlékezetben. Művészetek Háza Könyvek, 2015</p>
        </section>
      </main>
    </div>
  );
};

export default HomeEN;
