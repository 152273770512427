import React, { useRef, useState } from 'react';

import play from '../assets/images/play.svg';
import pause from '../assets/images/pause.svg';

import '../styles/audio.css';

function prettyTime (time) {
  let hours = Math.floor(time / 3600);
  let mins = '0' + Math.floor((time % 3600) / 60);
  let secs = '0' + Math.floor((time % 60));

  mins = mins.substr(mins.length - 2);
  secs = secs.substr(secs.length - 2);

  if (hours) {
    return `${hours}:${mins}:${secs}`;
  }
  return `${mins}:${secs}`;
}

const AudioPlayer = (props) => {
  const audioRef = useRef(null);
  const [count, setCount] = useState('00:00');
  const [total, setTotal] = useState('00:00');
  const [isPlaying, setPlaying] = useState(false);
  let progress = audioRef.current && audioRef.current.currentTime / audioRef.current.duration * 100;

  const seek = (e) => {
    if (audioRef.current) {
      const percent = e.nativeEvent.offsetX / e.target.offsetWidth;
      audioRef.current.currentTime = percent * audioRef.current.duration;
    }
  };

  return (
    <div id="player">
      <audio ref={audioRef}
             onLoadedData={() => setTotal(prettyTime(audioRef.current.duration))}
             onTimeUpdate={() => setCount(prettyTime(audioRef.current.currentTime))}
             onPlay={() => setPlaying(true)}
             onPause={() => setPlaying(false)}
      >
        <source src={props.url}/>
      </audio>
      <h1 className="title">{props.title}</h1>
      <div className="controls">
        {!isPlaying &&
        <div className="control" onClick={() => audioRef.current.play()}>
          <img src={play} alt="play"/>
        </div>
        }
        {isPlaying &&
        <div className="control" onClick={() => audioRef.current.pause()}>
          <img src={pause} alt="pause"/>
        </div>
        }
        <div className="progress-bar" onClick={seek}>
          <div style={{width: `${progress}%`}}></div>
        </div>
        <div className="timer">{count} / {total}</div>
      </div>
    </div>
  );
};

export default AudioPlayer;
