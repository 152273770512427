import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import close from '../assets/images/close.svg';
import menu from '../assets/images/menu.svg';

import { menuEN, menuHU } from '../data/menu';

import '../styles/menu.css';

const Menu = (props) => {
  const [isMenuOpen, setMenu] = useState(false);
  const menuItems = props.lang === 'HU' ? menuHU : menuEN;

  return (
    <div>
      {
        !isMenuOpen && (
          <div className="open button" onClick={() => setMenu(true)}>
            <img src={menu}/>
          </div>
        )
      }
      {
        isMenuOpen && (
          <div className="menu-wrapper">
            <div className="close button" onClick={() => setMenu(false)}>
              <img src={close}/>
            </div>
            <div className="menu">
              {menuItems.map(item => <Link to={item.url} className="menu-link">{item.name}</Link>)}
            </div>
          </div>
        )
      }

    </div>
  );
};

export default Menu;
