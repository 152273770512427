import React from 'react';
import { Link } from 'react-router-dom';

import hero_desktop from '../assets/images/pa_hero.jpg';
import hero_mobile from '../assets/images/pa_hero_mobile.jpg';
import intro from '../assets/audio/INTRO_hu.mp3';

import AudioPlayer from '../components/AudioPlayer';
import Menu from '../components/Menu';

import '../styles/home.css';

const Home = () => {
  const hero = window.innerWidth > 600 ? hero_desktop : hero_mobile;

  return (
    <div id="home">
      <header>
        <Link className="lang" to="/en">EN</Link>
        <Menu lang="HU"/>
        <div className="img-wrapper">
          <img src={hero} alt="Az arc, a medence, két torony és egy rom kiállítás"/>
        </div>
        <div className="info-wrapper">
          <div className="map">
              <p><a href="https://www.google.com/maps/d/viewer?mid=1YT3mecH7cjAVRyg37EjWYVhPzMRJj27w&hl=en&ll=47.106405161825535%2C17.918316056027606&z=15" target="_blank" rel="noopener noreferrer">Térkép</a></p>
          </div>
          <AudioPlayer url={intro} title="Bevezető"/>
        </div>
      </header>
      <main>
        <section>
          <h1>Az arc, a medence, két torony és egy rom kiállítás</h1>
          <h2>Kreatív városi beavatkozások a Haszkovón</h2>
          <p>A lakótelepek a világon mindenhol mostohák. A Haszkovó is az. Történetük egybeforrott az élhetetlenséggel, szürkeséggel, lélektelenséggel. Lehet szürke és élhetetlen valami, ami húszezer
            ember otthona? Lehetséges, hogy ezek a sztereotípiák csak meggátolnak minket abban, hogy valódi városként tekintsünk ezekre a lakótelepi negyedekre és ezzel kreatív ötletekkel egészítsük
            ki őket?</p>
          <p>A Design Hét Veszprém apropóján létrejövő kiállítás célja, hogy öt óriási és játékos bútor segítségével megpróbálja kiegészíteni a modern lakótelepek építészeti hiányosságait és
            bizonyítani, hogy a Haszkovó is ugyanolyan városnegyed, mint akár Veszprém belvárosa, éppen csak nem jöttek létre azok az elemei, amikre szívesen emlékszünk, olyanok mint a „Zsuzsi szobor”
            az „Óra” vagy a „Tűztorony”. A kiállítás ezek pótlására tesz kísérletet. A bútorok két hétig lesznek megtekinthetőek a Haszkovón, majd szavazást tartunk, hogy melyiket szeretnék a
            lakótelepen élők „megtartani” és melyiket ajándékoznák oda Veszprém más kerületeinek.</p>
          <p>A kiállítás a tavalyi 12 fal – építészet és kortárs ornamentika kiállítás után újra feltörekvő nemzetközi építészeket invitál Veszprémbe. Az öt installációt brit, görög, olasz, spanyol és
            magyar építészek tervezik, ezzel prezentálva öt ország építészeti látásmódját is.</p>
        </section>
        <section>
          <h2>Kiállító építészek:</h2>
          <p><a href="https://www.edwardcrooks.co.uk/tagged/news" target="_blank" rel="noopener noreferrer">Edward Crooks</a></p>
          <p><a href="http://www.pointsupreme.com/content/" target="_blank" rel="noopener noreferrer">Point Supreme</a></p>
          <p><a href="https://www.supervoid.eu/" target="_blank" rel="noopener noreferrer">Supervoid</a></p>
          <p><a href="https://www.maio-architects.com/" target="_blank" rel="noopener noreferrer">MAIO</a></p>
          <p><a href="https://paradigmaariadne.com/" target="_blank" rel="noopener noreferrer">Paradigma Ariadné</a></p>
        </section>
        <section>
          <h2>Impresszum</h2>
          <p>Szervezők: Paradigma Ariadné</p>
          <p>Kurátorok: Csóka Attila Róbert, Molnár Szabolcs, Smiló Dávid</p>
          <p>Grafika, webdesign: Császár Adrienn</p>
          <p>Code: Erő Júlia</p>
          <p>Audio guide copywriting, szerkesztés: Német Szilvi</p>
          <p> Audio guide, fordítás: Adele Eisenstein</p>
          <p>Hang designer: Bertók Márton</p>
          <p>Magyar hangok (megjelenési sorrendben):<br/>Kovács Kristóf, Huth Júliusz, Árkovics Lilla, Német Szilvi, Csóka Attila Róbert, Német Szilvi, Keresztes János, TTS, Keresztes János, Német
            Szilvi</p>
          <br/>
          <p>A kiállítás a Veszprém-Balaton 2023 Zrt. támogatásával a Paradigma Ariadné szervezésében jött létre.</p>
        </section>
        <section>
          <h2>A hanganyagban felhasznált hivatkozások</h2>
          <p>Beshty, Waled & Schwab, Eric: Wilkommen in Irak! Cabinet Magazine. Issue 15 / The Average</p>
          <p>Friedman, Alice T.: American Glamour 2.0: architecture, spectacle and social media. Consumption, Markets & Culture, 2017</p>
          <p>Jencks, Charles: The language of Post-Modern Architecture</p>
          <p>Lynch, Kevin: The Image of the City. MIT Press, 1960</p>
          <p>Lange, Alexandra: Is Instagram Ruining Architecture? The New York Times, 2019</p>
          <p>Moore, Rowan : Pruitt-Igoe: death of the American urban dream. The Observer, Architecture. 2012</p>
          <p>Rossi, Aldo: The Architecture of the City. Oppositions Books, 1984</p>
          <p>Rubin, Eli: Amnesiopolis: modernity, space and memory in East Germany. Oxford University Press, 2016</p>
          <p>Új építészet, új társadalom 1945-1978. Válogatás az elmúlt évtizedek építészeti vitáiból, dokumentumaiból. Művészet és elmélet. Corvina kiadó, 1981</p>
          <p>Útkeresés a ’70-es évek lakásépítésében I. Lechner Tudásközpont, 2017</p>
          <p>Városi folklór. A veszprémi Jutasi úti lakótelep az emlékezetben. Művészetek Háza Könyvek, 2015</p>
        </section>
      </main>
    </div>
  );
};

export default Home;
