import arc from '../assets/images/arc.png';
import medence from '../assets/images/medence.png';
import torony1 from '../assets/images/torony1.png';
import torony2 from '../assets/images/torony2.png';
import rom from '../assets/images/rom.png';

import arcPhoto from '../assets/images/haszkovo_web_arc.jpg';
import medencePhoto from '../assets/images/haszkovo_web_medence.jpg';
import torony1Photo from '../assets/images/haszkovo_web_torony1.jpg';
import torony2Photo from '../assets/images/haszkovo_web_torony2.jpg';
import romPhoto from '../assets/images/haszkovo_web_rom.jpg';

import track1_hu from '../assets/audio/track1_hu.mp3';
import track2_hu from '../assets/audio/track2_hu.mp3';
import track3_hu from '../assets/audio/track3_hu.mp3';
import track4_hu from '../assets/audio/track4_hu.mp3';
import track5_hu from '../assets/audio/track5_hu.mp3';

import track1_en from '../assets/audio/track1_en.mp3';
import track2_en from '../assets/audio/track2_en.mp3';
import track3_en from '../assets/audio/track3_en.mp3';
import track4_en from '../assets/audio/track4_en.mp3';
import track5_en from '../assets/audio/track5_en.mp3';



export const data = {
  FACE: {
    img: arc,
    photo: arcPhoto,
    artist: 'Point Supreme',
    link: 'http://www.pointsupreme.com/content/',
    map: 'https://www.google.com/maps/place/47%C2%B006\'23.9%22N+17%C2%B055\'16.5%22E/@47.1066411,17.9199152,465m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0x47699add028c2f91:0x400c4290c1e1210!2zVmVzenByw6lt!3b1!8m2!3d47.1028087!4d17.9093019!3m5!1s0x0:0x0!7e2!8m2!3d47.1066354!4d17.9212566',
    HU: {
      title: 'Az Arc',
      text: [
        'A „Szembe Szoba” maradéktalanul kihozza az épület dimenzióiból megvalósítható maximumot, és Haszkovó hatalmas nyitott területét egy egészen apró épülettel egészíti ki: egy szobával. A szoba hivatkozási és azonosítási alapul szolgál a területnek; a páratlanság remek példája. Állandó színhelyet biztosít a lakók közötti kapcsolatfelvételnek, a meghittséget biztosító közeg pedig véletlenszerű társulások létrejöttét inspirálja majd a félig elrejtett belső téren belül és körülötte egyaránt.',
        'A szoba emberi arcként mutatja be magát, egyértelművé téve barátságos mivoltát, és a szó szoros értelmében úgy tűnik fel, mint egy „barát”, akire mindig lehet számítani és aki hajlandó találkozni is velünk. Stratégia szempontok alapján lett a játszótér mellett elhelyezve, hogy bátorítsa az optimizmust, a játékosságot és a tapasztalatokra kész viselkedést, felszabadítva a mindennapi életet a megszokott tevékenységek sorozata alól a gyerekek és a felnőttek számára egyaránt.',
        'Haszkovó másik 4 „Városi Ereklyéjével” együtt a „Szembe Szoba” lehetőséget nyújt az épületek közötti hatalmas nyitott területen a városiasság szükséges érzete és a közélet kialakulásának, ezáltal biztosítva kulturális szerepet a közterületnek, és visszaadva annak úticél jellegű mivoltát. A kollektív tudatalattit egy új, nyilvános, kritikai gondolkodással táplálja. Cseppet sem szembetűnő homlokzatát egy 50 éves japán háztól kölcsönözte, és igyekszik késznek tűnni, a figyelmet pedig az eredet helyett a hatásra helyezi, ezáltal ösztönözve az építészetről alkotott felfogás egy újfajta változatának kialakulását.'
      ],
      audio: track1_hu,
      trackTitle: 'A mi és az ők. Panelbeszélgetés'
    },
    EN: {
      title: 'The Face',
      text: [
        '"Face Room" fully materializes the maximum building dimensions possible and provides the vast open space of Haszkovó with a small building: a room. The room acts as a point of reference and identification for the area, a precious point of uniqueness. It will provide a permanent place for contact for the residents, a space for intimacy inspiring random associations around and inside its semi- concealed interior.',
        'The room presents itself as a human face, clearly stating its friendly nature and quite literally acting as a ‘friend’ that is always there, willing to meet. Strategically placed next to a playground, it inspires optimism, play and experimental behaviour, liberating everyday life from the usual habitual actions for both children and adults.',
        'Together with the other 4 Urban Artifacts of Haszkovó, ‘Face Room’ anchors a much needed sense of urbanity and public life in the vast open space between buildings, adding to the public space a cultural role and turning it back into a destination. It feeds the collective subconscious with a new, public, critical imagination. Having borrowed its anti-spectacular façade design from a 50-year-old house in Japan, it acts as a readymade, fully placing attention to its effect rather than its originality, helping to inspire a new awareness of the role of architecture. '
      ],
      audio: track1_en,
      trackTitle: 'Other-defined /  self-defined. If buildings could talk...'
    },
  },
  POOL: {
    img: medence,
    photo: medencePhoto,
    artist: 'MAIO',
    link: 'https://www.maio-architects.com/',
    map: 'https://www.google.com/maps/place/47%C2%B006\'26.1%22N+17%C2%B054\'59.4%22E/@47.1072601,17.9152098,445m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0x47699add028c2f91:0x400c4290c1e1210!2zVmVzenByw6lt!3b1!8m2!3d47.1028087!4d17.9093019!3m5!1s0x0:0x0!7e2!8m2!3d47.107258!4d17.9164863',
    HU: {
      title: 'Medence',
      text: [
        'Nem sokkal délután három óra után 1973. április 22-én egy 35 éves, Robert Maitland nevű építész épp London központjában a Westway csomópontnál hajtott fel a gyorsforgalmi lehajtó sávra. Hatszáz yardnyira volt már csak az újonnan épült elágazástól az M4-es autópálya kereszteződésénél, amikor a Jaguar elhagyta a 70 mérföld/órás sebességkorlátot, és a járda felőli oldalon lévő első kerék defektet kapott. A jármű egyik oldalról a másikra sodródott az üres forgalmi sávok között, bábuként rántva magával a sofőr kezét. Az irányíthatatlan kocsi belecsapódott a fenyő cölöpökből kirakott sánckerítésbe, amely ideiglenes korlátként jelezte az út szélét. A burkolt leállósávot maga mögött hagyva végigszáguldott a töltés mentén lévő füves lejtőn, majd harminc yarddal odébb egy felborult taxi rozsdásodó karosszériájának ütközve megállt. Az életétveszélyes manővert szinte sértetlenül megúszó Robert Maitland a kormánykeréken feküdt, kabátját és nadrágját beborította a széttört szélvédő ezernyi fénylő szilánkja.',
        'Magához térésének eme első pár percében Robert Maitland az ütközésből alig emlékezett többre, mint a felrobbant gumiabroncs hangjára, a felüljáró alatti alagutat elhagyva a kocsit érő hirtelen napfény érzésére, és az arcát beborító széttört szélvédőre. A jármű egy lejtős területen pihent meg, az ablakon túl pedig mindenhol derékmagasságig érő csalán és gyom borította be a láthatárt.',
        'Maitland a fűtengeren átvergődve megközelítette a kocsi elejét. Egy röpke pillantás elég volt ahhoz, hogy belássa, esélye sincs elvezetni a járművet a legközelebbi bekötőútig. A kocsi eleje teljesen behorpadt, mintha... Kezével eltakarva a szeme elől a napot Maitland észrevette, hogy egy mintegy kétszáz yard hosszan elnyúló, háromszög alakú forgalmi szigetnek száguldott neki, amely a három autópálya vonal csomópontjánál lévő senki földjén terült el. Az alapját a dél felé tartó felüljáró alkotta, amely hetven lábbal a talaj fölött süvített keresztül. A súlyos beton tartóoszlopokon nyugvó felüljáró hat forgalmi sávját kitakarták a hullámosított fémből készült felverődésgátlók, amelyek a lentebb száguldó járművek védelmét szolgálták. Maitland mögött húzódott a sziget északi fala, a nyugatnak tartó autópálya harminc láb magas töltése, amelyről röviddel azelőtt becsapódott. Vele szemben futott végig a sziget déli határvonala: a három sávos bekötőút meredek töltése, amely északnyugati irányt véve kanyarodott el a felüljáró alatt, majd a sziget csúcsánál egyesült az autópályával.',
        'Maitland megfordult, miközben egy reptéri transzfer busz elhaladt mellette az autópályán. A felső szint utasai – Zürich, Stuttgart és Stockholm felé tartva – mereven ültek az ülésükön, akár egy csapatnyi próbababa. Nyilvánvaló volt, hogy a szigetről kivezető egyetlen út a töltéseken át vezetett.'
      ],
      audio: track3_hu,
      trackTitle: 'Érzéskultúra 53 m2-en'
    },
    EN: {
      title: 'The Pool',
      text: [
        'Soon after three o\'clock on the afternoon of April 22nd 1973, a 35-year-old architect named Robert Maitland was driving down the high-speed exit lane of the Westway interchange in central London. Six hundred yards from the junction with the newly built spur of the M4 motorway, when the Jaguar had already passed the 70 m.p.h. speed limit, a blow-out collapsed the front nearside tyre. The car veered from side to side across the empty traffic lanes, jerking his hands like a puppet\'s. Out of control, the car burst through the palisade of pinewood trestles that formed a temporary barrier along the edge of the road. Leaving the hard shoulder, the car plunged down the grass slope of the embankment. Thirty yards ahead, it came to a halt against the rusting chassis of an overturned taxi. Barely injured by this violent tangent that had grazed his life, Robert Maitland lay across his steering wheel, his jacket and trousers studded with windshield fragments like a suit of lights.',
        'In these first minutes as he recovered, Robert Maitland could remember little more of the crash than the sound of the exploding tyre, the swerving sunlight as the car emerged from the tunnel of an overpass, and the shattered windshield stinging his face. The car had come to rest on sloping ground, surrounded by nettles and wild grass that grew waist-high outside the windows.',
        'Maitland waded through the grass to the front of his car. A single glance told him that he had no hope of driving it to a nearby access road. The front end had been punched into itself like a collapsed Shielding his eyes from the sunlight, Maitland saw that he had crashed into a small traffic island, some two hundred yards long and triangular in shape, that lay in the waste ground between three converging motorway routes. The base was formed by the southbound overpass that swept past seventy feet above the ground. Supported on massive concrete pillars, its six lanes of traffic were sealed from view by the corrugated metal splash-guards installed to protect the vehicles below. Behind Maitland was the northern wall of the island, the thirty-feet-high embankment of the westbound motorway from which he had crashed. Facing him, and forming the southern boundary, was the steep embankment of the three-lane feeder road which looped in a north-westerly circuit below the overpass and joined the motorway at the apex of the island.',
        'Maitland turned as an airline coach passed along the motorway. The passengers on the upper deck, bound for Zurich, Stuttgart and Stockholm, sat stiffly in their seats like a party of mannequins. Clearly there was no exit from the island other than the embankments.\n'
      ],
      audio: track3_en,
      trackTitle: 'AsmRCHITECTURE and the culture of affects'
    },
  },
  TOWER1: {
    img: torony1,
    photo: torony1Photo,
    artist: 'Supervoid',
    link: 'https://www.supervoid.eu/',
    map: 'https://www.google.com/maps/place/47%C2%B006\'34.4%22N+17%C2%B055\'18.0%22E/@47.1095561,17.9203784,447m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0x47699add028c2f91:0x400c4290c1e1210!2zVmVzenByw6lt!3b1!8m2!3d47.1028087!4d17.9093019!3m5!1s0x0:0x0!7e2!8m2!3d47.1095539!4d17.9216627',
    HU: {
      title: 'Torony I.',
      text: [
        'A veszprémi Haszkovó lakótelep egy tipikus szocialista-modernista vívmány, amely az előregyártott vasbeton panelek túlzott használatával épült – a 20. század egy rövidebb időszakában ez ugyanis a szociális lakásépítés olcsó és gyors módjának számított.',
        'A térségben található kellő mennyiségű zöldterület ellenére az épületek elrendezése és az építési eljárás jellege megakadályozta, hogy talajszinten bármiféle változtatást is kieszközöljenek vagy kifejezésre juttassanak a háztömbök vonatkozásában. Mindez pedig egy városi birodalom létrejöttéhez vezetett, amelyben az áthatolhatatlan épületek csupán laza térbeli kapcsolatban állnak a köztük elterülő hatalmas terekkel.',
        'A területen megtalálható James Stirling Haslemere-ben épült Olivetti képzési központjának egy 1:1 méretarányú töredéke. Az intervenció rámutat azokra az építészeti elemekre, amelyek teljes mértékben hiányoznak a Haszkovó lakótelepről. Néhány lépcső és oszlop, ezek tetején pedig egy túlméretezett oszlopfő – ezek az elemek tagolják Stirling épületének előcsarnokát, amelyet a Haszkovó lakóteleppel egy időben terveztek. Ezen elemek építészeti jelenléte és arányai egy bizonyos mértékű monumentalitást és feltehetőleg ormótlan, ugyanakkor erőteljes vizuális hatást adnak a szóban forgó tárgynak. A városi környezet alapvető elemei közül így több is fellelhető egy meglehetősen kompakt tárgyban. Úgy gondoljuk, hogy a földszint ilyen jellegű térbeli összehangolása az, ami hiányzik a Haszkovó épületekből, valamint épp ez lehetne a kiindulópontja az épületek és a közterület közötti kapcsolat jövőbeli változtatásainak.',
        'A furcsa, zöld színű totem így emlékeztetőül szolgál arra, hogy igenis lehetséges egy épületet a városnak megfelelően tervezni, ugyanakkor a várost is igazíthatjuk az épülethez.'
      ],
      audio: track2_hu,
      trackTitle: 'Az építészet a közösségi média korszakában'
    },
    EN: {
      title: 'Tower I.',
      text: [
        'Haszkovó housing estate in Veszprém is a typical socialist modernist development, built through the extensive use of prefabricated reinforced concrete panels, which represented for a short period of the XXth century a cheap and fast way of building social housing.',
        'Despite the decent amount of green spaces in the area, the layout of the buildings and the very nature of the construction technique have prevented any articulation or modifications of the blocks to happen at the ground level. This results in an urban realm made of rather impermeable buildings that have a weak spatial relationship with the vast open spaces between them.',
        'A reproduction in 1:1 scale of a fragment of James Stirling’s Olivetti training centre in Haslemere is placed on the site. The intervention points out those architectural elements which are completely missing from the Haszkovó housing estate. A few steps and columns topped by an oversized capital are the elements that articulate the concourse of Stirling’s building, which was designed in the same period of the Haszkovó housing estate. The architectural presence and proportions of these elements give to the object a certain degree of monumentality and a possibly clunky but powerful visual impact. Many of the fundamental elements that make an urban environment are thus present in a very compact object. We argue that this kind of spatial articulation of the ground floor is what is lacking from the buildings of Haszkovó, and it could be the starting point for future modifications of the relationships that the buildings entertain with the public space.',
        'This strange green totem thus stands as a reminder of the fact that you can design a building as a city and the city as a building.'
      ],
      audio: track2_en,
      trackTitle: 'Building Influencers'
    },
  },
  TOWER2: {
    img: torony2,
    photo: torony2Photo,
    artist: 'Edward Crooks',
    link: 'https://www.edwardcrooks.co.uk/tagged/news',
    map: 'https://www.google.com/maps/place/47%C2%B006\'18.1%22N+17%C2%B055\'15.9%22E/@47.1050281,17.9198123,440m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0x47699add028c2f91:0x400c4290c1e1210!2zVmVzenByw6lt!3b1!8m2!3d47.1028087!4d17.9093019!3m5!1s0x0:0x0!7e2!8m2!3d47.1050259!4d17.9210744',
    HU: {
      title: 'Torony II.',
      text: [
        'A városban, ahol életem első tizennyolc évét töltöttem, volt egy apró toronycsúcs, amely oda nem illő módon üldögélt a főparkban, a rózsaágyások közepén terpeszkedve. A toronycsúcsnak nem volt semmi közvetlen funkciója, nem volt rajta óra, harang, sem ülés, sem szélkakas. Mégis majdnem három évtizeden át központi szerepet töltött be a közösségről ápolt képemben, mint megannyi egymást követő jelentős esemény háttere.',
        'Gyermekként egyre csak fűtött a kíváncsiság, mivel teljesen összezavart a játékszerekkel teli park közepén álló értelmetlen ostobaság jelenléte. Kamaszként már a csínytettek helyszínévé vált, és a sör ízével való megbarátkozás, vagy egy-egy csók elcsattanásának szemtanúja volt. Felnőttként pedig már ennél a műremeknél gyűltünk össze, hogy poharunkat emeljük édesanyám 60. születésnapja alkalmából.',
        'A toronycsúcs valójában egy sokkal nagyobb épület töredéke volt – egy harangtoronyhoz tartozott, amelyet a tetőtől lefele megcsonkított egy, a város szívében fekvő hatalmas gyárépület. Habár az épület harangja évtizedek óta nem szólalt meg, a puszta jelenléte gyújtópontként szolgált azoknak a mindennapi tevékenységeknek, amelyek közösségeket alakítanak ki.',
        'Szeretem úgy képzelni, hogy a gyárépület mélyen el van temetve a park alatt, és egyedül az apró toronycsúcs látszik ki, eltakarva minden hozzátartozó dolgot, jól elrejtve a mögötte lapuló teljesebb képet. Az évtizedek alatt csak úgy egymagában mint egy városi töredék, ez az apró tárgy a felszín alatt hatalmas mennyiségű emléket, pillanatot és vágyálmot halmozott fel. Az ehhez hasonló töredékek alkotják kollektív identitásunk szívét.',
        'Ez a Veszprémben található darab egy sokkal méretesebb szerkezet töredéke – egy velencei harangtorony csúcsa, amely megközelíti a kora New York-i felhőkarcolók koronájának nagyszabású geometriáját –, egy elmerült világra utaló apró emlékeztető.',
        'Funkcióját tekintve furcsán céltalan, a darab mégis felkelti a szemlélő kíváncsiságát – szinte nyitva áll, ezzel egy időben viszont nehéz bejutni, látszólag lakható, mégis túlságosan szűk, szinte emberi léptékű, ugyanakkor kissé túl zömök. Az egész épület csintalanságról árulkodik – a szerkezet használatának mindennemű formája „helytelen”, hiszen ez esetben nem létezik olyan, hogy „helyes”.',
        'Az épületbe belépve a szűkös falak között eltűnik az ember a kíváncsiskodók szeme elől – mindez pedig szükségszerű meghittséget eredményez, és tökéletes helyszínt biztosít az első csókhoz, amelyre mindketten vágytok, mégis túl bátortalanok vagytok belevágni.',
        'Orgonák tengerében találod magad, a lila pedig a sóvárgás színe – sóvárgás az ártatlanság és a fiatalság után. Az orgona maga a vonzalom, az első szerelem és a gyengéd románc.  A lila színkavalkádba mártózva a toronycsúcs egy rég elfeledett pillanat homályos jelenéseként tűnik fel – egy elvont, valótlan műemlék, mely készen áll arra, hogy új emlékeket és vágyálmokat szívjon magába.'
      ],
      audio: track4_hu,
      trackTitle: 'Építészeti szakszótár'
    },
    EN: {
      title: 'Tower II.',
      text: [
        'In the town where I lived the first eighteen years of my life there was a small spire that sat incongruously in the main park, stranded amongst beds of roses. The spire served no direct function, having no clock, no bell, no seating and no weather vane. Yet for almost three decades it has sat at the heart of my memory of this community, the backdrop of successive significant events.',
        'As a child it served as a vessel for my curiosity, confused at the presence of this pointless folly in park of playthings. As a teenager it was the locus for those memories of causing mischief, learning to like beer and daring to steal kisses. As an adult it was at this monument that we gathered to raise a toast to my mother\'s 60th birthday.',
        'This spire was in fact a fragment of a much larger building - a bell tower, amputated from the roof of a large factory building in the middle of the town. Whilst its bell has not rung for decades, its presence alone has been enough to provide a focal point for the everyday activities that form communities.',
        'I like to imagine this factory building buried deep beneath the park, only the small spire protruding, concealing all that is attached to it, hiding the bigger picture beneath. Just as, being an urban fragment, this small object has, over decades accumulates beneath the surface a vast collection of shared memories, moments and desires. Fragments such as these form the heart of our collective identities.',
        'This piece in Veszprém aims to be a fragment of a much larger structure – the pinnacle of a Venetian bell tower meeting the extravagant geometry of an early  New York skyscrapers crown – just a hint at a submerged world below.',
        'Oddly impractical in its function, the piece invites intrigue – almost open yet simultaneously hard to enter, seemingly habitable yet slightly too cramped, almost human scale but a little too squat. In this it invites mischief– every way of using the structure is ‘wrong’ as there is no ‘right’.',
        'When inside you are obscured from view and cramped – it necessitates intimacy, a perfect spot for that first kiss that you both want but are too shy to go for.',
        'You are bathed in lilac, the colour of nostalgia - of innocence and youth. Lilac is affection, first loves and gentle romance.  Dowsed in this colour, the piece exists as a hazy apparition of a forgotten moment - an abstracted, unreal monument ready to absorb new memories and desires.'
      ],
      audio: track4_en,
      trackTitle: 'Alexa, define “Urban Artifact”'
    },
  },
  RUIN: {
    img: rom,
    photo: romPhoto,
    artist: 'Paradigma Ariadné',
    link: 'https://paradigmaariadne.com/',
    map: 'https://www.google.com/maps/place/47%C2%B006\'24.6%22N+17%C2%B054\'52.4%22E/@47.1068381,17.9132818,445m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0x47699add028c2f91:0x400c4290c1e1210!2zVmVzenByw6lt!3b1!8m2!3d47.1028087!4d17.9093019!3m5!1s0x0:0x0!7e2!8m2!3d47.1068356!4d17.914558',
    HU: {
      title: 'Rom',
      text: [
        'A Haszkovón nagyon sok olyan városi elem nem található meg, amely Veszprém belvárosában igen. Mi ebből egy elemet vettünk példának mégpedig a romot. A rom mint városi elem a legtöbb valódi történettel rendelkező város esetében mindig kiemelt helyzetben van, így Veszprémben a Margit-romok is. Ezek az elemek a régi felértékelődésének fizikai bizonyítékai, amelyeket nem bontunk el egy-egy infrastrukturális fejlesztés során, és amelyek már elég régiek ahhoz, hogy egy egész város idomuljon hozzájuk, ne pedig ők maguk a városhoz.',
        'Installációnk, két szempontból vizsgálja a romok városidentitást meghatározó problémakörét. Egyfelől lehetséges-e úgy szemlélni egy romot, hogy az idővel nem számolunk, azaz a rom öregségét nem vesszük figyelembe? Másfelől azt a kérdés járja körbe, hogy lehetséges-e egy egyértelműen a történeti építészethez köthető formának – lásd a rom-szerű forma – modern identitást és funkciót adni?',
        'Az előbbi kérdésre a válasz talán egyszerűbb, hiszen a történelem bizonyos korszakaiban, de különösen a 19. században fordult már elő, hogy kertek, parkok díszítése céljából műromok jöttek létre, amelyek rom-szerűen voltak megtervezve, hogy ezzel megidézzenek egy régmúlt hangulatot. Hova tovább, ha a vidámparkokat szemléljük, akkor láthatjuk, hogy bizonyos formai szabályokat betartva létrejöhetnek „romok” papírmaséból vagy egyéb időszaki építőanyagból is.',
        'A vidámparkok kísértet kastélyai ténylegesen nem romok, de azok akarnak lenni, amely törekvésük lehet megmosolyogtató, de bútor-installációnk célja, hogy a mosolyon túl gondolatra késztessen minket: mi az, ami a romokban annyira vonz minket? Fel lehet ezt a vonzalmat építeni anélkül, hogy a romnak lenne múltja és romra jellemző kő anyaga? Lehet műanyag a rom?',
        'És ha lehet a rom műanyag is, akkor miként rendelhetünk hozzá a Haszkovót is meghatározó modern identitást? Ennek megközelítésénél már önkényesebbek voltunk, valamint meg akartunk felelni a Haszkovón telepített installációkkal kapcsolatos egyik alapelvárásnak: lehessen használni, kreatív városi padként is a létrejövő tárgyat. A modern korral kapcsolatban sokan elfelejtik, hogy bár ez az összetett ideológia és szemlélet volt az, ami megteremtette a Haszkovót is, de ugyanez a szemlélet hozta el az ergonomikus, azaz az emberi test valós fizikai adottságait követő, kiszolgáló bútorok és belsőépítészeti elemek tervezését is. Erre reflektálva négy olyan szék keresztmetszetének töréseit vettük alapul a rom megtervezéséhez, amely a modern kánon részei és amelyeket modernista építészek terveztek. Gerrit Rietveld „Vörös és kék” székjét, Alvar Aalto „Paimio” székjét, Breuer Marcell „Wassily” vagy más néven „B3” székét valamint Charles és Ray Eames leghíresebb heverőjét.',
        'Az álalunk tervezett rom csonkaságát és letöréseit nem az idő alakította tehát, de még csak nem is egy vágy arra, hogy öregnek tűnő tárgyat hozzunk létre, hanem modern székek keresztmetszetei, amelynek köszönhetően létrejött a „kényelmes rom”.'
      ],
      audio: track5_hu,
      trackTitle: 'Városi legendák és képzelt közösségek'
    },
    EN: {
      title: 'Ruin',
      text: [
        'There are several urban elements that are missing from Haszkovó, though they are present at the downtown of Veszprém. We chose one of these elements, namely the ruins. Ruins as urban elements are of major significance in case of every city that has a real history, such as, for example, the Margaret ruins in Veszprém. These elements are the physical evidences for the appreciation of the past, which will not be demolished in the course of infrastructural improvements, and which are old enough to enjoy the privilege of having a whole city conforming to them instead of conforming to the city themselves.',
        'Our installation examines the issues relating to the ruins’ role in defining the city’s identity from two aspects. On the one hand, is it possible to look at the ruins in a way that we forget about time, in other words, we do not take into consideration the actual age of the ruins? On the other hand, is it possible to attribute a modern identity and function to a shape—see the ruin-like shape—that is distinctly related to historical architecture?',
        'Answering the above question might be easier, as in certain periods of history, but especially throughout the 19th century, there were instances of people creating artificial ruins for the decoration of gardens and parks. These were designed to look ruin-like, so that they could summon a bygone atmosphere. Moreover, if we just think about amusement parks in general, we can see that in line with certain formal requirements, it is possible to create “ruins” from papier-mâché or other temporary construction materials as well. The haunted mansions of the amusement parks are not actual ruins, though they are aiming at being one, which effort might put a smile on our faces, however, the objective of our furniture-installation is to go further than a smile and make us think: what is that we find so attractive in ruins? Could we establish this sort of attraction even if the ruin had no past and would not be made of the stone materials that are so characteristic to ruins? Could a ruin even be made of plastic?',
        'And if so, how could we attribute a modern identity to the ruins, similar to the one which defines Haszkovó as well? We were more arbitrary when dealing with this issue, moreover, we wanted to satisfy one of the basic requirements concerning the installations at Haszkovó: the installed items should also be usable as creative city benches. When talking about modern age, people tend to forget that even though this complex ideology and approach provided the basis for the creation of Haszkovó, it was this very same approach that brought us the ergonomic—that is capable of following the actual physical characteristics of the human body—furniture and interior design elements. As a reflection upon this, we used the fractures of the cross-section of four chairs as a basis for the ruins’ design. These chairs are all parts of the modern canon and were designed by modernist architects—Gerrit Rietveld’s “Red and Blue Chair”, Alvar Aalto’s “Paimio Chair”, Marcel Breuer’s “Wassily Chair” also known as the “Model B3 Chair”, and the most famous sofa of Charles and Ray Eames.',
        'Hence the defects and flaws of the ruins designed by us were not shaped by time or the desire to create a seemingly old object, but were based on the modern chairs’ cross-sections, which lead to the establishment of the “comfortable ruin”.'
      ],
      audio: track5_en,
      trackTitle: 'Long live the past that tells us who we are'
    },
  },
};
