import React from 'react';

import AudioPlayer from '../components/AudioPlayer';
import Menu from '../components/Menu';

import marker from '../assets/images/marker.svg';
import '../styles/artifact.css';

const Artifact = (props) => {
  const {data, lang} = props;
  const artist = lang === 'HU' ? 'Tervező' : 'Designer';
  const map = lang === 'HU' ? 'Térkép' : 'Map';

  return (
    <>
      <header>
        <Menu lang={lang} page="artifact-page"/>
      </header>
      <main id="artifact">
        <h1>{data[lang].title}</h1>
        <div className="row">
          <div className="image">
            <div className="img-wrapper">
              <img src={data.img} alt={data[lang].title}/>
            </div>
            <div className="marker">
              <a href="https://www.google.com/maps/d/viewer?mid=1YT3mecH7cjAVRyg37EjWYVhPzMRJj27w&hl=en&ll=47.106405161825535%2C17.918316056027606&z=15" target="_blank" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer">
                <img src={marker} alt="map marker"/>
              </a>
            </div>
            <div className="photo-wrapper">
              <img src={data.photo} alt={data[lang].title}/>
            </div>
          </div>
          <div className="info">
            <AudioPlayer url={data[lang].audio} title={data[lang].trackTitle} location={props.location}/>
            <div className="description">
              <a href="https://www.google.com/maps/d/viewer?mid=1YT3mecH7cjAVRyg37EjWYVhPzMRJj27w&hl=en&ll=47.106405161825535%2C17.918316056027606&z=15" target="_blank" rel="noopener noreferrer" target="_blank" rel="noopener noreferrer">
                {map}
              </a>
              <p>{artist}: <a href={data.link} target="_blank" rel="noopener noreferrer">{data.artist}</a></p>
              {data[lang].text.map(line => <p>{line}</p>)}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Artifact;
