import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import './App.css';

import Artifact from './pages/Artifact';
import Home from './pages/Home';
import HomeEN from './pages/HomeEN';

import {data} from './data/main';

function App () {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <Route path="/" exact component={Home}/>
          <Route path="/en" exact component={HomeEN}/>
          <Route path="/arc" key={2} exact render={(props) => <Artifact {...props} data={data.FACE} lang='HU'/>}/>
          <Route path="/face" key={3} exact render={(props) => <Artifact {...props} data={data.FACE} lang='EN'/>}/>
          <Route path="/medence" key={4} exact render={(props) => <Artifact {...props} data={data.POOL} lang='HU'/>}/>
          <Route path="/pool" key={5} exact render={(props) => <Artifact {...props} data={data.POOL} lang='EN'/>}/>
          <Route path="/toronyegy" key={6} exact render={(props) => <Artifact {...props} data={data.TOWER1} lang='HU'/>}/>
          <Route path="/towerone" key={7} exact render={(props) => <Artifact {...props} data={data.TOWER1} lang='EN'/>}/>
          <Route path="/toronyketto" key={8} exact render={(props) => <Artifact {...props} data={data.TOWER2} lang='HU'/>}/>
          <Route path="/towertwo" key={9} exact render={(props) => <Artifact {...props} data={data.TOWER2} lang='EN'/>}/>
          <Route path="/rom" key={10} exact render={(props) => <Artifact {...props} data={data.RUIN} lang='HU'/>}/>
          <Route path="/ruin" key={11} exact render={(props) => <Artifact {...props} data={data.RUIN} lang='EN'/>}/>
        </Switch>
      </HashRouter>
    </div>
  );
}

export default App;
